import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import { TextField, Button, CircularProgress, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { WalletConnect } from '@layouts/WalletConnect';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storeProfile } from '@actions';

const Index = memo(() => {
    const theme = useTheme();
    // const styles = useStyles();
    const { t } = useTranslation();
    const { username, crypto_address } = useSelector(state => state.user);
    const { modal, address } = WalletConnect();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        username: '',
        referralName: '',
        show: false
    })
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    useEffect(() => {
        if (crypto_address || username) {
            setState({ ...state, show: true })
        }
    }, [crypto_address, username])

    const completeProfile = () => {
        setLoading(true);
        let params;
        // if (web_three) {
            params = {
                username: state.username,
                referral_name: state.referralName,
            }
        // } else {
        //     params = {
        //         crypto_address: address,
        //     }
        // }
        putUrl(`/complete-profile`, params).then(response => {
            if (response.status) {
                setState({ ...state, username: "", referralName: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
                dispatch(storeProfile(response.data));
            } else {
                setInputErrors(response.errors);
                if (response.errors['crypto_address']) {
                    addAlert('', response.errors['crypto_address'], 'error', '');
                } else {
                    addAlert('', t('error.editError'), 'error', '');
                }
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='flex-col' style={{ gap: 24, marginTop: '80px', padding: '0 20px 150px' }}>
            {
                state.show ?
                    <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                        <div>
                            <p className='fs-16 p-b-5'>{t('completeProfile.title')}</p>
                            <p className='lh-14'>{t('completeProfile.subtitle')}</p>
                        </div>
                        <div className='p-t-24'>
                            {/* web3 set username and referral */}
                            {
                                (!username) ?
                                    <div>
                                        <div className='p-b-10'>
                                            <p className='fs-14 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('user.setUsername')}</p>
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                name='username'
                                                type="text"
                                                placeholder={t('user.enterUsername')}
                                                onChange={handleChange}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                                error={inputErrors && inputErrors.username ? true : false}
                                            />
                                        </div>
                                        <div className='p-b-10'>
                                            <p className='fs-14 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('user.referralName')}</p>
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                name='referralName'
                                                type="text"
                                                placeholder={t('user.referralName')}
                                                onChange={handleChange}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                                                error={inputErrors && inputErrors.referral_name ? true : false}
                                            />
                                        </div>
                                        <Button fullWidth variant="contained" onClick={completeProfile}><p>{t('button.confirm')}</p></Button>
                                    </div>
                                    : null
                            }
                            {/* web2 connect wallet grab wallet address */}
                            {/* {
                                (!web_three && !crypto_address) ?
                                    <div>
                                        <p className='fs-16 txt-left txt-title p-b-10 clwhite'>{t('login.connectWallet')}</p>
                                        <p className='fs-14 txt-left p-b-10 lh-14' style={{ color: theme.palette.textColor }}>{t('user.cryptoAddress')}: {address || '-'}</p>
                                        <div className='flex-m bg-text bor15 p-all-16 pointer' onClick={() => address ? completeProfile() : modal.open()}>
                                            <div className='bor15 flex-c-m p-all-10' style={{ width: 40, height: 40, background: '#3496FF' }}>
                                                <img src='/images/general/wallet-connect.png' alt="icon" className='w-full' />
                                            </div>
                                            <p className='p-l-10'>{t('button.walletConnect')}</p>
                                        </div>
                                    </div>
                                    : null
                            } */}
                        </div>
                    </div>
                    :
                    <div className='flex-c-m txt-center p-t-50'>
                        <CircularProgress className='fs-50' />
                    </div>
            }
        </div>
    )
});

// const useStyles = makeStyles((theme) => ({
// }));

export default Index;