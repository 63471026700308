import React, { memo, useState, useEffect } from 'react';
import { public_restricted_routes, public_routes, private_routes } from '@configs/Router';
import _ from 'lodash';
import { getUrl, postUrl, putUrl, removeLoginAccess } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@mui/styles';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Tooltip, Avatar, Link, Button, Drawer, TextField, InputAdornment, Grid, Typography, CircularProgress } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, matchRoutes, useParams } from 'react-router-dom';
import { changeLanguage, storeProfile } from '@actions';
import moment from 'moment-timezone';
import { currencyFormat, reducePaths, storePaths, truncate } from '@utils/Tools';
import { WalletConnect } from './WalletConnect';
import { useDisconnect } from 'wagmi';
import useNotificationLoading from '@utils/useNotificationLoading';
import { QRCodeSVG } from 'qrcode.react';
import { ethers } from 'ethers';

import { FiChevronLeft, FiChevronRight, FiArrowLeft, FiCheck, FiEyeOff, FiEye, FiX, FiExternalLink } from "react-icons/fi";
import { AiOutlinePoweroff } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import { IoAlertCircleOutline, IoLanguage } from "react-icons/io5";
import { FaGlobe } from "react-icons/fa";
import MenuIcon from '@mui/icons-material/Menu';

import Login from '@pages/Auth/Login';
import Register from '@pages/Auth/Register';
import DrawerMenu from '@layouts/DrawerMenu';

const systemLanguages = ['en', 'cn'];

const INITIAL_STATE = { showTutorBtn: false, showBackBtn: false, backUrl: null, tutorUrl: null, showLogo: true, noHeader: false };

const NAVIGATOR = { 'home': '/', 'games': '/games', 'community': '/community', 'swapMKA': '/swap/mka', 'swapMKC': '/swap/mkc' };

// const SWAP_SETTING = {
//     'jfa': 'JFA Token',
//     'jfg': 'JFG Token',
//     'jfu': 'JFU Token',
//     'jfs': 'JFS Token',
//     'usdt': 'Tether USD',
// }

const TitleBar = memo((props) => {
    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [option, setOption] = useState(INITIAL_STATE);
    const [anchorEl, setAnchorEl] = useState(null);
    const { accessToken, language } = useSelector(state => state.general);
    const { username, name, web_three, crypto_address, two_factor_confirmed_at } = useSelector(state => state.user);
    const open = anchorEl?.attributes?.['data-ref']?.value;//Boolean(anchorEl);
    const { modal, address } = WalletConnect();
    const { disconnect } = useDisconnect();
    const { addAlert, setLoading } = useNotificationLoading();

    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;
    let { id } = useParams();
    const [serverTime, setServerTime] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerTab, setDrawerTab] = useState("token");
    const [drawerSettingOpen, setDrawerSettingOpen] = useState(false);
    const [drawerLanguageOpen, setDrawerLanguageOpen] = useState(false);
    const [drawerConnectOpen, setDrawerConnectOpen] = useState(false);
    const [drawerLoginOpen, setDrawerLoginOpen] = useState(false);
    const [drawerRegisterOpen, setDrawerRegisterOpen] = useState(false);
    const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
    const [settingStepper, setSettingStepper] = useState(1);
    const [inputErrors, setInputErrors] = useState({});
    const [showPassword, setShowPassword] = useState({
        current_password: false,
        password: false,
        password_confirmation: false,
    });
    const [state, setState] = useState({
        current_password: '',
        password: '',
        password_confirmation: '',
        secret: '',
        authenticationCode: ''
    })
    const [enableAuth, setEnableAuth] = useState(false);
    const [disableAuth, setDisableAuth] = useState(false);
    const [qrcode, setQrcode] = useState([]);
    const [balance, setBalance] = useState({})
    const [balanceUpdateLoading, setBalanceUpdateLoading] = useState(false);
    const [activityListing, setActivityListing] = useState([]);
    const explorerLink = process.env.NODE_ENV == 'development' ? 'https://testnet.bscscan.com/tx/' : 'https://bscscan.com/tx/';
    const addressExplorerLink = process.env.NODE_ENV == 'development' ? 'https://testnet.bscscan.com/address/' : 'https://bscscan.com/address/';

    const handleClickShowPassword = (target) => setShowPassword({ ...showPassword, [target]: !showPassword?.[target] });
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClose = (event = null) => {
        if (event) event.stopPropagation()
        setAnchorEl(null);
    };

    useEffect(() => {
        let public_restricted_detail = matchRoutes(public_restricted_routes, current_path)?.[0]?.['route'];
        let public_detail = matchRoutes(public_routes, current_path)?.[0]?.['route'];
        let private_detail = matchRoutes(private_routes, current_path)?.[0]?.['route'];

        if (_.size(public_restricted_detail) > 0) {
            setOption({ ...option, showBackBtn: public_restricted_detail?.showBackBtn, showTutorBtn: public_restricted_detail?.showTutorBtn, showLogo: public_restricted_detail?.showLogo, tutorUrl: public_restricted_detail?.tutorUrl, backUrl: public_restricted_detail?.backUrl, backTitle: t(public_restricted_detail?.backTitle), title: t(public_restricted_detail?.title), noHeader: public_restricted_detail?.noHeader });

        } else if (_.size(public_detail) > 0) {
            setOption({ ...option, showBackBtn: public_detail?.showBackBtn, showTutorBtn: public_detail?.showTutorBtn, showLogo: public_detail?.showLogo, tutorUrl: public_detail?.tutorUrl, backUrl: public_detail?.backUrl, title: t(public_detail?.title), backTitle: t(public_detail?.backTitle), noHeader: public_detail?.noHeader });

        } else if (_.size(private_detail) > 0) {
            setOption({ ...option, showBackBtn: private_detail?.showBackBtn, showTutorBtn: private_detail?.showTutorBtn, showLogo: private_detail?.showLogo, tutorUrl: private_detail?.tutorUrl, backUrl: private_detail?.backUrl, title: t(private_detail?.title), backTitle: t(private_detail?.backTitle), noHeader: private_detail?.noHeader });

        } else {
            setOption(INITIAL_STATE);
        }

    }, [current_path, t]);

    // useEffect(() => {
    //     moment.tz.setDefault("Asia/Kuala_Lumpur");
    //     let secTimer = setInterval(() => {
    //         setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
    //     }, 1000)

    //     return () => clearInterval(secTimer);
    // }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    const handleDrawerTabChange = destination => {
        navigate(destination, { state: { from: storePaths(location) } });
        setDrawerOpen(false);
    }

    const handleSettingDrawer = () => {
        setDrawerOpen(false);
        setDrawerSettingOpen(true);
    }

    useEffect(() => {
        if (i18n.language == "ur") {
            document.documentElement.dir = 'rtl'
        } else {
            document.documentElement.dir = 'ltr';
        }
        document.documentElement.lang = i18n.language;
        // eslint-disable-next-line
    }, [i18n.language]);

    const goBack = () => {
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing = reducePaths(location);
            let urlLink = path?.pathname;
            if (path?.search !== '') {
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    const logout = () => {
        removeLoginAccess();
        navigate('/');
        if (address) {
            modal.disconnect();
        }
        disconnect();
        setDrawerOpen(false);
        setDrawerTab('token');
        setActivityListing([]);
    };

    useEffect(() => {
        if (web_three && crypto_address && address != crypto_address) {
            logout();
        }
    }, [web_three, crypto_address, address])

    useEffect(() => {
        if (accessToken) {
            setDrawerConnectOpen(false);
            setDrawerLoginOpen(false);
            setDrawerRegisterOpen(false);
            updateBalance();
        }
    }, [accessToken])

    useEffect(() => {
        if (!drawerSettingOpen || settingStepper == 1) {
            setSettingStepper(1);
            /* password & security password */
            setState({ ...state, current_password: "", password: "", password_confirmation: "" });
            /* 2fa */
            setDisableAuth(false);
            setEnableAuth(false);
            /* input error */
            setInputErrors([]);
        }
        // eslint-disable-next-line
    }, [drawerSettingOpen, settingStepper])

    useEffect(() => {
        if (drawerTab == 'activity') {
            getTokenActivityListing()
        } else {
            setActivityListing([]);
        }
        // eslint-disable-next-line
    }, [drawerTab])

    const getTokenActivityListing = () => {
        setLoading(true);
        getUrl(`/user/wallet-listing`).then(response => {
            if (response.status) {
                setActivityListing(response.data.data);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    const updateBalance = () => {
        setBalanceUpdateLoading(true);
        getUrl(`/wallets/swap-setting`).then(response => {
            if (response.status) {
                setBalance({
                    ...balance,
                    ...response.data.wallet_balances
                })
            }
        }).catch(error => {
        }).finally(() => {
            setBalanceUpdateLoading(false);
        });
    }

    const updatePassword = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            current_password: state.current_password,
            password: state.password,
            password_confirmation: state.password_confirmation,
        }
        putUrl(`/user/password`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, current_password: "", password: "", password_confirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const updateSecurityPassword = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            current_password: state.current_password,
            password: state.password,
            password_confirmation: state.password_confirmation,
        }
        putUrl(`/user/security-password`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, current_password: "", password: "", password_confirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    /* 2FA */
    const getSecret = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            type: 'enable',
        }
        postUrl(`authenticator`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, secret: response.secret });
                setQrcode(response.url);
                setEnableAuth(true);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const verifyCode = () => {
        setLoading(true);
        setInputErrors([]);
        let params = {
            type: 'confirm',
            authentication_code: state.authenticationCode,
        }
        postUrl(`authenticator`, params).then(response => {
            setState({ ...state, authenticationCode: "" });
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.profile));
                addAlert('', t('success.enable2FASuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const disableTwoFactorAuth = () => {
        setLoading(true);
        setInputErrors([]);
        let params = {
            type: 'disable',
            authentication_code: state.authenticationCode,
        }
        postUrl(`authenticator`, params).then(response => {
            setState({ ...state, authenticationCode: "" });
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.profile));
                setDisableAuth(false);
                setEnableAuth(false);
                addAlert('', t('success.disable2FASuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // CHATBOT
    // useEffect(() => {
    //     const head = document.querySelector("head");
    //     const script = document.createElement("script");
    //     window.$crisp=[];window.CRISP_WEBSITE_ID="d55d5s335-9sb8c-4d1s7-805sf-4d7s5bb076bf5";
    //     script.setAttribute("src", "https://client.crisp.chat/l.js");
    //     head.appendChild(script);

    //     var css = '#crisp-chatbox span.cc-imbb.cc-qfnu {  background-color: #404b7d82 !important; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)!important; box-shadow: 3px 3px 3px 0 #00000042 !important }';

    //     var style = document.createElement('style');
    //     head.appendChild(style);

    //     style.type = 'text/css';
    //     if (style.styleSheet) {
    //         // This is required for IE8 and below.
    //         style.styleSheet.cssText = css;
    //     } else {
    //         style.appendChild(document.createTextNode(css));
    //     }

    //     return () => {
    //         head.removeChild(script);
    //     };
    // }, []);

    return (
        option?.noHeader === true ?
            <div className='flex-sb-m p-lr-26 p-t-20 p-b-10'>
                {option?.showBackBtn &&
                    <div style={{ zIndex: 3 }}>
                        {option?.backUrl ?
                            <Link underline='none' to={option?.backUrl} component={RouterLink} state={{ from: storePaths(location) }} className='flex-m' style={{ color: '#fff' }}>
                                <FiArrowLeft /><p className='p-l-10 fs-16'>{option?.backTitle}</p>
                            </Link>
                            : <div className='flex-m pointer txt-white' onClick={goBack}>
                                <FiArrowLeft /><p className='p-l-10 fs-16'>{option?.backTitle}</p>
                            </div>}
                    </div>
                }
                {
                    option?.showLogo &&

                    <div className='p-t-6'>
                        <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' state={{ from: storePaths(location) }} >
                            <img src='/logo_text.png' alt="logo" style={{ width: 36 }} />
                        </Link>
                    </div>
                }
                <p className='txt-center clwhite fs-16'>{option?.title}</p>
                <div>
                    {/* <div className='txt-right txt-white p-r-5 fs-12 lh-12' style={{ letterSpacing: '-0.2px' }}>
                        <p>{t('general.serverTime')}</p>
                        <p className='p-t-5'>{serverTime}</p>
                    </div> */}
                    {/* <Tooltip title={t('general.language')} arrow> */}
                    <div data-ref="language" onClick={() => setDrawerLanguageOpen(true)} className='p-r-10 pointer' style={{ lineHeight: 0 }}>
                        <FaGlobe className='fs-icon' style={{ color: theme.palette.textColor }} />
                    </div>
                    {/* </Tooltip> */}
                </div>
                {/* Language Drawer */}
                <Drawer
                    anchor="bottom"
                    open={drawerLanguageOpen}
                    onClose={() => setDrawerLanguageOpen(false)}
                >
                    <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center', overflowY: 'auto', marginBottom: 60 }}>
                        {/* select Langauge */}
                        <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                            <div className='flex-m w-full'>
                                <p className='w-full fs-16 txt-title txt-center' style={{ marginRight: -20 }}>{t('general.language')}</p>
                                <FiX className='fs-icon pointer' onClick={() => setDrawerLanguageOpen(false)} />
                            </div>
                        </div>
                        <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24 }}>
                            {
                                _.map(systemLanguages, (_languages, index) => (
                                    <div className='flex-sb-m w-full pointer' key={index} onClick={() => changeSystemLanguage(_languages)} >
                                        <p>{t(`general.${_languages}`)}</p>
                                        {
                                            language == _languages &&
                                            <FiCheck className='fs-icon' style={{ color: theme.palette.primary.main, width: 16, height: 16 }} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Drawer>
            </div>
            :
            <div>
                <AppBar position='fixed' className='m-t-20' sx={{ maxWidth: '100%', width: 444, right: 'auto', marginTop: 0 }}>
                    <Toolbar className='flex-sb-m w-full'>
                        {
                            option?.showLogo &&

                            <div className='p-t-6'>
                                <Link component={RouterLink} to={accessToken ? "/" : "/login"} underline='none' state={{ from: storePaths(location) }} >
                                    <img src='/logo_text.png' alt="logo" style={{ width: 36 }} />
                                </Link>
                            </div>
                        }
                        <div className='flex-m'>
                            {/* <Tooltip title={t('general.language')} arrow> */}
                            <div data-ref="language" onClick={() => setDrawerLanguageOpen(true)} className='p-r-10 pointer' style={{ lineHeight: 0 }}>
                                <FaGlobe className='fs-icon' style={{ color: theme.palette.textColor }} />
                            </div>
                            {/* </Tooltip> */}
                            {/* {
                                accessToken ?
                                    <IconButton data-ref="profile" onClick={() => setDrawerOpen(true)} style={{ fontSize: 14, textTransform: "capitalize" }}><Avatar sx={{ width: 32, height: 32, marginRight: 1, pointerEvents: "none" }}><img src='/images/general/profile-button.png' alt='profile' /></Avatar>{name}</IconButton>
                                    :
                                    <Button onClick={() => setDrawerConnectOpen(true)} style={{ marginRight: 10 }}>{t('general.connect')}</Button>
                            } */}
                            {
                                accessToken && <Button variant="contained" onClick={() => setDrawerOpen(true)} style={{ marginRight: 10, lineHeight: "20px" }}>{`${crypto_address.slice(0,4)}...${crypto_address.slice(-4)}`}</Button>
                            }
                            {
                                !accessToken && <Button variant="contained" onClick={() => setDrawerConnectOpen(true)} style={{ marginRight: 10, lineHeight: "20px" }}>{t('general.connect')}</Button>
                            }
                            <IconButton aria-label="menu" onClick={() => setDrawerMenuOpen(true)} style={{ backgroundColor: '#D2EFFF', borderRadius: 8 }}>
                                <MenuIcon style={{ color: theme.palette.button.main }}/>
                            </IconButton>
                        </div>
                    </Toolbar>
                    {/* {
                        current_path == '/complete-profile' ? null
                            :
                            <div className='flex-l-m p-l-25 p-tb-20'>
                                {
                                    _.map(NAVIGATOR, (link, label) => {
                                        return (
                                            <Link key={label} component={RouterLink} to={link} underline='none' state={{ from: storePaths(location) }} >
                                                <p className='p-lr-10' style={{ color: current_path == link ? '#FFFFFF' : theme.palette.textColor }}>{t(`title.${label}`)}</p>
                                            </Link>
                                        )
                                    })
                                }

                            </div>
                    } */}
                    {/* <div className='flex-sb-m p-lr-15 p-tb-10'>
                        {option?.showBackBtn &&
                            <div style={{ zIndex: 3 }}>
                                {option?.backUrl ?
                                    <Link underline='none' to={option?.backUrl} component={RouterLink} state={{ from: storePaths(location) }} className='flex-m' style={{ color: '#fff' }}>
                                        <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                    </Link>
                                    : <div className='flex-m pointer txt-white' onClick={goBack}>
                                        <FiChevronLeft /><p className='p-l-10 fs-14'>{option?.backTitle}</p>
                                    </div>}
                            </div>
                        }

                        <div className='txt-right txt-white p-r-5 fs-12 lh-12' style={{ letterSpacing: '-0.2px' }}>
                            <p>{t('general.serverTime')}</p>
                            <p className='p-t-5'>{serverTime}</p>
                        </div>
                        {option?.showTutorBtn &&
                            <Link className='p-b-15' component={RouterLink} to={option?.tutorUrl} underline='none' state={{ from: storePaths(location) }} >
                                <div className='button-theme pointer txt-center' style={{ width: 100 }}>{t('title.tutorial')}</div>
                            </Link>
                        }
                    </div> */}
                </AppBar>
                {option?.showBackBtn &&
                    <div className='m-t-140'>
                    </div>
                }
                <Drawer
                    anchor="bottom"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                >
                    <div className='p-b-40' style={{ width: 'auto', padding: '20px', paddingBottom: 40 }}>
                        <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                            <div className='flex-m w-full'>
                                <p className='w-full fs-16 txt-title' style={{ marginRight: -20, fontWeight: 600 }}>{t('title.myWallet')}</p>
                                <FiX className='fs-icon pointer' onClick={() => setDrawerOpen(false)} />
                            </div>
                        </div>
                        {/* <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                            <div className='flex-l-m'>
                                <img src='/images/general/profile-button.png' style={{ height: 32, aspectRatio: 1 }} alt='profile' />
                                <p className='clwhite m-l-10 fs-16 txt-title'>{username}</p>
                            </div>
                            <div className='flex-r-m'>
                                {
                                    !web_three &&
                                    <IoMdSettings className='pointer' onClick={handleSettingDrawer} style={{ color: theme.palette.textColor }} />
                                }
                                <AiOutlinePoweroff className='m-l-20 pointer' style={{ color: theme.palette.textColor }} onClick={logout} />
                            </div>
                        </div> */}
                        {/* <div className='flex-sa-m' style={{ gap: 10 }}>
                            <div className='p-all-15 w-full flex-col-l flex-sb pointer' style={{ borderRadius: 16, background: theme.palette.primary.secondary, minHeight: 100, minWidth: 170 }} onClick={() => handleDrawerTabChange('/community')}>
                                <img src='/images/general/membership-icon.png' style={{ height: 24, width: 24 }} alt='membership' />
                                <p className='fs-16' style={{ color: theme.palette.primary.main, fontWeight: 400 }}>{t('title.community')}</p>
                            </div>
                            <div className='p-all-15 w-full flex-col-l flex-sb pointer' style={{ borderRadius: 16, background: theme.palette.primary.secondary, minHeight: 100, minWidth: 170 }} onClick={() => handleDrawerTabChange('/swap')}>
                                <img src='/images/general/swap-icon.png' style={{ height: 24, width: 24 }} alt='swap' />
                                <p className='fs-16' style={{ color: theme.palette.primary.main, fontWeight: 400 }}>{t('title.swap')}</p>
                            </div>
                        </div> */}
                        <div className="p-10 flex-sb p-all-20 bor10" style={{ backgroundColor: theme.palette.backgroundColor}}>
                            <div>
                                <Typography style={{ color: theme.palette.gray.main, fontSize: 12 }}>{t('button.walletConnect')}</Typography>
                                <Typography style={{ color: theme.palette.textColor, fontSize: 16 }}>{`${crypto_address.slice(0,4)}...${crypto_address.slice(-4)}`}</Typography>
                            </div>
                            <div>
                                <Button variant="outlined" onClick={logout} style={{ lineHeight: "20px" }}>{t('button.disconnect')}</Button>
                            </div>
                        </div>
                        <div className='flex-l p-t-30' style={{ gap: 15 }}>
                            {
                                _.map(['token', 'activity'], label => {
                                    return (
                                        <p className='pointer fs-16' key={label} style={{ color: drawerTab == label ? theme.palette.textColor : theme.palette.gray.main }} onClick={() => setDrawerTab(label)}>{t(`title.${label}`)}</p>
                                    )
                                })
                            }
                        </div>
                        <div className='w-full txt-left p-t-10'>
                            {
                                drawerTab == 'token' &&
                                <div>
                                    <div className='p-b-10 p-r-10 txt-right fs-14 cl-theme'>
                                        {
                                            balanceUpdateLoading ?
                                                <CircularProgress style={{ width: 20, height: 20 }} />
                                                :
                                                <p className='p-b-12 pointer' onClick={() => updateBalance()}>{t('button.refresh')}</p>
                                        }
                                    </div>
                                    <div>
                                        {
                                            _.map(balance, walletBalance => (
                                                <div className='flex-m p-b-24' key={walletBalance.id}>
                                                    {/* <img src={`/images/wallet/token-${walletBalance.code}.png`} alt={walletBalance.code} style={{ width: 40, height: 40 }} /> */}
                                                    <div style={{ width: 40, height: 40, backgroundColor: '#000A3D24', borderRadius: '50%'}}></div>
                                                    <div className='p-l-15'>
                                                        <p className='txt-title fs-14' style={{ color: theme.palette.textColor }}>{walletBalance.name[i18n.language]}</p>
                                                        <p className='txt-upper fs-14 p-t-8' style={{ color: theme.palette.gray.main }}>{currencyFormat(walletBalance.balance || 0, walletBalance.decimal)} {walletBalance.code.toUpperCase()}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            {
                                drawerTab == 'activity' &&
                                <div style={{ maxHeight: '300px' }}>
                                    {
                                        web_three ?
                                            <p className='p-b-10 fs-14 pointer cl-theme flex-r-m' onClick={() => window.open(addressExplorerLink + crypto_address + '#tokentxns', "_blank", "noreferrer")}>{t('swaps.viewExplorer')}<FiExternalLink className='m-l-3' /></p>
                                            : null
                                    }
                                    {
                                        _.size(activityListing) > 0 &&
                                        <>
                                            {
                                                _.map(activityListing, (data, index) => {
                                                    return (
                                                        <div key={index} className='p-t-10 p-b-20' style={{ color: theme.palette.textColor, overflowY: 'hidden' }}>
                                                            {
                                                                web_three ?
                                                                    <>
                                                                        <div className='flex-sb-m'>
                                                                            <p>{data?.tokenName}</p>
                                                                            <p style={{ color: (crypto_address).toLowerCase() == data?.to ? '#00ff1e' : '#ff2424' }}>{currencyFormat(ethers.formatEther(data.value || ''), 2)} {data?.tokenSymbol}</p>
                                                                        </div>
                                                                        <div className='flex-sb-m p-t-10 fs-12'>
                                                                            <p className='txt-truncate pointer' style={{ width: '50%' }} onClick={() => window.open(explorerLink + data?.hash, "_blank", "noreferrer")}>{data.hash}</p>
                                                                            <p>{moment.unix(data.timeStamp).format('DD/MM/YYYY h:mm:ss A')}</p>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className='flex-sb-m'>
                                                                            <p>{data?.transaction_code_display?.details[i18n.resolvedLanguage || 'en'] || '-'}</p>
                                                                            <p style={{ color: data.factor == 1 ? '#00ff1e' : '#ff2424' }}>{currencyFormat(data.amount || 0, 2)} {data.wallet_balance?.wallet.name[i18n.resolvedLanguage || 'en'] || '-'}</p>
                                                                        </div>
                                                                        <div className='flex-sb-m p-t-10 fs-12'>
                                                                            <p className='txt-truncate'>{data?.ulid}</p>
                                                                            <p>{data?.created_at}</p>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </Drawer>

                {/* Setting Drawer */}
                <Drawer
                    anchor="bottom"
                    open={drawerSettingOpen}
                    onClose={() => setDrawerSettingOpen(false)}
                >
                    <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center', height: '80vh', overflowY: 'auto' }}>
                        {/* setting */}
                        {
                            settingStepper == 1 &&
                            <div>
                                <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                                    <div className='flex-m w-full clwhite pointer' onClick={() => { setDrawerSettingOpen(false); setDrawerOpen(true) }}>
                                        <FiArrowLeft className='fs-icon' />
                                        <p className='w-full fs-16 txt-title txt-center' style={{ marginLeft: -20 }}>{t('title.setting')}</p>
                                    </div>
                                </div>
                                <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                                    <div className='flex-sb-m w-full'>
                                        <p className='clwhite'>{t('user.username')}</p>
                                        <p className='p-r-10'>{username || '-'}</p>
                                    </div>
                                    <div className='flex-sb-m w-full'>
                                        <p className='clwhite'>{t('withdraw.withdrawalAddress')}</p>
                                        <div className='flex-m'>
                                            <p className='p-r-5'>{truncate(crypto_address || '-', 16)}</p>
                                            {/* <FiChevronRight className='fs-icon' /> */}
                                        </div>
                                    </div>
                                    <div className='flex-sb-m w-full pointer' onClick={() => setSettingStepper(2)}>
                                        <p className='clwhite'>{t('user.password')}</p>
                                        <div className='flex-m'>
                                            {/* <p className='p-r-5'>{t('settings.notSetup')}</p> */}
                                            <FiChevronRight className='fs-16' />
                                        </div>
                                    </div><div className='flex-sb-m w-full pointer' onClick={() => setSettingStepper(3)}>
                                        <p className='clwhite'>{t('user.securityPassword')}</p>
                                        <div className='flex-m'>
                                            {/* <p className='p-r-5'>{t('settings.notSetup')}</p> */}
                                            <FiChevronRight className='fs-16' />
                                        </div>
                                    </div>
                                    <div className='flex-sb-m w-full pointer' onClick={() => setSettingStepper(4)}>
                                        <p className='clwhite'>{t('user.authenticationCode')}</p>
                                        <div className='flex-m'>
                                            {/* <p className='p-r-5'>{t('settings.notSetup')}</p> */}
                                            <FiChevronRight className='fs-16' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Set Withdrawal Address */}
                        {/* <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                            <div className='flex-m w-full clwhite'>
                                <FiArrowLeft className='fs-icon pointer' onClick={() => setSettingStepper(1) />
                                <p className='w-full fs-16 txt-title txt-center' style={{ marginLeft: -20 }}>{t('settings.setWithdrawalAddress')}</p>
                            </div>
                        </div>
                        <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                            <div className='bg-text p-all-16 bor10 flex-c-m w-full'>
                                <IoAlertCircleOutline className='fs-16' />
                                <div className='p-l-10' style={{ width: 'calc(100% - 16px)' }}>
                                    <p className='fs-12 txt-left lh-12'>{t('settings.setWithdrawalAddressDescription')}</p>
                                </div>
                            </div>
                            <div>
                                <p className='fs-16 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('settings.yourWithdrawalAddress')}</p>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    name='withdrawalAddress'
                                    type="text"
                                    placeholder="0"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            <Button fullWidth variant="contained"><p>{t('button.confirm')}</p></Button>
                        </div> */}

                        {/* Set Password */}
                        {
                            settingStepper == 2 &&
                            <div>
                                <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                                    <div className='flex-m w-full clwhite pointer' onClick={() => setSettingStepper(1)} >
                                        <FiArrowLeft className='fs-icon' />
                                        <p className='w-full fs-16 txt-title txt-center' style={{ marginLeft: -20 }}>{t('settings.setPassword')}</p>
                                    </div>
                                </div>
                                <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                                    <div>
                                        <p className='fs-16 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('user.currentPassword')}</p>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            value={state.current_password}
                                            name='current_password'
                                            onChange={handleChange}
                                            type={showPassword.current_password ? 'text' : 'password'}
                                            placeholder={t('user.currentPassword')}
                                            helperText={inputErrors && inputErrors.current_password ? inputErrors.current_password : ''}
                                            error={inputErrors && inputErrors.current_password ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" style={{ paddingRight: 20 }}>
                                                        <IconButton
                                                            onClick={() => handleClickShowPassword('current_password')}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            style={{ color: theme.palette.textColor }}
                                                        >
                                                            {showPassword.current_password ? <FiEye /> : <FiEyeOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <p className='fs-16 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('user.yourPassword')}</p>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            value={state.password}
                                            name='password'
                                            onChange={handleChange}
                                            type={showPassword.password ? 'text' : 'password'}
                                            placeholder={t('settings.enterPassword')}
                                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                            error={inputErrors && inputErrors.password ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" style={{ paddingRight: 20 }}>
                                                        <IconButton
                                                            onClick={() => handleClickShowPassword('password')}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            style={{ color: theme.palette.textColor }}
                                                        >
                                                            {showPassword.password ? <FiEye /> : <FiEyeOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <p className='fs-16 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('user.confirmPassword')}</p>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            value={state.password_confirmation}
                                            name='password_confirmation'
                                            onChange={handleChange}
                                            type={showPassword.password_confirmation ? 'text' : 'password'}
                                            placeholder={t('user.confirmPassword')}
                                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" style={{ paddingRight: 20 }}>
                                                        <IconButton
                                                            onClick={() => handleClickShowPassword('password_confirmation')}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            style={{ color: theme.palette.textColor }}
                                                        >
                                                            {showPassword.password_confirmation ? <FiEye /> : <FiEyeOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <Button fullWidth disabled={!state.current_password || !state.password || !state.password_confirmation} variant="contained" onClick={updatePassword}><p>{t('button.confirm')}</p></Button>
                                </div>
                            </div>
                        }
                        {/* Set Security Password */}
                        {
                            settingStepper == 3 &&
                            <div>
                                <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                                    <div className='flex-m w-full clwhite pointer' onClick={() => setSettingStepper(1)} >
                                        <FiArrowLeft className='fs-icon' />
                                        <p className='w-full fs-16 txt-title txt-center' style={{ marginLeft: -20 }}>{t('settings.setSecurityPassword')}</p>
                                    </div>
                                </div>
                                <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                                    <div>
                                        <p className='fs-16 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('user.currentPassword')}</p>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            value={state.current_password}
                                            name='current_password'
                                            onChange={handleChange}
                                            type={showPassword.current_password ? 'text' : 'password'}
                                            placeholder={t('user.currentPassword')}
                                            helperText={inputErrors && inputErrors.current_password ? inputErrors.current_password : ''}
                                            error={inputErrors && inputErrors.current_password ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" style={{ paddingRight: 20 }}>
                                                        <IconButton
                                                            onClick={() => handleClickShowPassword('current_password')}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            style={{ color: theme.palette.textColor }}
                                                        >
                                                            {showPassword.current_password ? <FiEye /> : <FiEyeOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <p className='fs-16 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('settings.yourSecurityPassword')}</p>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            value={state.password}
                                            name='password'
                                            onChange={handleChange}
                                            type={showPassword.password ? 'text' : 'password'}
                                            placeholder={t('settings.enterSecurityPassword')}
                                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                            error={inputErrors && inputErrors.password ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" style={{ paddingRight: 20 }}>
                                                        <IconButton
                                                            onClick={() => handleClickShowPassword('password')}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            style={{ color: theme.palette.textColor }}
                                                        >
                                                            {showPassword.password ? <FiEye /> : <FiEyeOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <p className='fs-16 txt-left p-b-10' style={{ color: theme.palette.textColor }}>{t('user.confirmSecurityPassword')}</p>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            value={state.password_confirmation}
                                            name='password_confirmation'
                                            onChange={handleChange}
                                            type={showPassword.password_confirmation ? 'text' : 'password'}
                                            placeholder={t('user.confirmSecurityPassword')}
                                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" style={{ paddingRight: 20 }}>
                                                        <IconButton
                                                            onClick={() => handleClickShowPassword('password_confirmation')}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                            style={{ color: theme.palette.textColor }}
                                                        >
                                                            {showPassword.password_confirmation ? <FiEye /> : <FiEyeOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <Button fullWidth disabled={!state.current_password || !state.password || !state.password_confirmation} variant="contained" onClick={updateSecurityPassword}><p>{t('button.confirm')}</p></Button>
                                </div>
                            </div>
                        }
                        {/* 2FA */}
                        {
                            settingStepper == 4 &&
                            <div className='p-t-24'>
                                <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                                    <div className='flex-m w-full clwhite pointer' onClick={() => setSettingStepper(1)} >
                                        <FiArrowLeft className='fs-icon' />
                                        <p className='w-full fs-16 txt-title txt-center' style={{ marginLeft: -20 }}>{t('user.2FA')}</p>
                                    </div>
                                </div>
                                <div className='fs-14'>
                                    {
                                        !two_factor_confirmed_at && !enableAuth &&
                                        <div>
                                            <div>
                                                <p className='lh-14' style={{ textAlign: 'justify', color: theme.palette.textColor }} >{two_factor_confirmed_at === 1 ? t('user.enabled2FA') : t('user.2FASubtitle')}</p>
                                            </div>
                                            <div className='p-t-24'>
                                                <Button variant="contained" onClick={getSecret} ><p>{t('button.enable')}</p></Button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        two_factor_confirmed_at && !disableAuth &&
                                        <>
                                            <div className='p-b-24'>
                                                <p className='lh-14' style={{ textAlign: 'justify', color: theme.palette.textColor }} >{t('user.disable2FACaption')}</p>
                                            </div>

                                            <Button variant="contained" fullWidth onClick={() => setDisableAuth(true)} >{t('button.disable')}</Button>
                                        </>
                                    }
                                    {
                                        two_factor_confirmed_at && disableAuth &&
                                        <>
                                            <div>
                                                <p className='lh-14' style={{ textAlign: 'justify', color: theme.palette.textColor }} >{t('user.disable2FACaption')}</p>
                                            </div>
                                            <div className='p-tb-24'>
                                                <p className='fs-16 p-b-10 txt-left'>{t('user.authenticationCode')}</p>
                                                <TextField
                                                    error={inputErrors.authentication_code ? true : false}
                                                    helperText={inputErrors?.authentication_code || ''}
                                                    placeholder={t('user.enterAuthentication')}
                                                    variant="standard"
                                                    type="number"
                                                    value={state.authenticationCode}
                                                    name="authenticationCode"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{ disableUnderline: true }}
                                                />
                                            </div>
                                            <Button variant="contained" onClick={disableTwoFactorAuth} ><p>{t('button.submit')}</p></Button>
                                        </>
                                    }
                                    {
                                        !two_factor_confirmed_at && enableAuth &&
                                        <div className='p-t-20 flex-col' style={{ color: theme.palette.textColor, gap: 24 }}>
                                            <div>
                                                <p className='p-b-10'>{t('user.scan2FAQrcode')}</p>
                                                <div className='bgwhite p-all-5 bor5' style={{ width: 'fit-content', margin: '0 auto', lineHeight: 0 }}>
                                                    <QRCodeSVG value={qrcode} />
                                                </div>
                                            </div>
                                            <div>
                                                <p className='fs-12 cl-inactive p-b-10'>{t('user.insertSecret')}</p>
                                                <p className='clwhite'><b>{t('user.secretKey', { secret: state.secret })}</b></p>
                                            </div>
                                            {/* <Grid item xs={12}>
                                                <Typography variant="body2">{t('user.verifyCode')}</Typography>
                                            </Grid> */}
                                            <div>
                                                <p className='fs-16 p-b-10 txt-left'>{t('user.authenticationCode')}</p>
                                                <TextField
                                                    error={inputErrors.authentication_code ? true : false}
                                                    helperText={inputErrors?.authentication_code || ''}
                                                    placeholder={t('user.enterAuthentication')}
                                                    variant="standard"
                                                    type="number"
                                                    fullWidth
                                                    value={state.authenticationCode}
                                                    name="authenticationCode"
                                                    onChange={handleChange}
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{ disableUnderline: true }}
                                                />
                                            </div>
                                            <Button variant="contained" onClick={verifyCode} ><p>{t('button.submit')}</p></Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Drawer>

                {/* Language Drawer */}
                <Drawer
                    anchor="bottom"
                    open={drawerLanguageOpen}
                    onClose={() => setDrawerLanguageOpen(false)}
                >
                    <div style={{ width: 'auto', padding: '20px 20px 40px', textAlign: 'center', overflowY: 'auto' }}>
                        {/* select Language */}
                        <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                            <div className='flex-m w-full'>
                                <p className='w-full fs-16 txt-title txt-center' style={{ marginRight: -20 }}>{t('general.language')}</p>
                                <FiX className='fs-icon pointer' onClick={() => setDrawerLanguageOpen(false)} />
                            </div>
                        </div>
                        <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24 }}>
                            {
                                _.map(systemLanguages, (_languages, index) => (
                                    <div className='flex-sb-m w-full pointer' key={index} onClick={() => changeSystemLanguage(_languages)} >
                                        <p>{t(`general.${_languages}`)}</p>
                                        {
                                            language == _languages &&
                                            <FiCheck className='fs-icon' style={{ color: theme.palette.primary.main, width: 16, height: 16 }} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Drawer>

                {/* Connect Drawer */}
                <Drawer
                    anchor="bottom"
                    open={drawerConnectOpen}
                    onClose={() => setDrawerConnectOpen(false)}
                >
                    <div className='pos-relative' style={{ width: 'auto', padding: '20px', overflowY: 'auto' }}>
                        <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                            <div className='flex-m w-full'>
                                <p className='w-full fs-16 txt-title' style={{ fontWeight: 600 }}>{t('login.connectWallet')}</p>
                                <FiX className='fs-icon pointer' onClick={() => setDrawerConnectOpen(false)} />
                            </div>
                        </div>
                        <div className='h-full' >
                            <div className='flex-col w-full p-b-40' style={{ gap: 32 }}>
                                <Button fullWidth variant="contained" onClick={() => { setDrawerConnectOpen(false); modal.open() }} style={{ justifyContent: "flex-start", padding: "10px 16px"}}>
                                    <div className='bor15 flex-c-m p-all-10' style={{ width: 36, height: 36 }}>
                                        <img src='/images/general/wallet-connect.png' alt="icon" className='w-full' />
                                    </div>
                                    <p className='p-l-10 fs-16'>{t('button.walletConnect')}</p>
                                </Button>

                                {/* <div className='flex-sb-m'>
                                    <div className='w-full bg-inactive' style={{ height: 1 }} />
                                    <p className='p-lr-15 cl-inactive' style={{ whiteSpace: 'nowrap' }}>{t('login.otherOption')}</p>
                                    <div className='w-full bg-inactive' style={{ height: 1 }} />
                                </div>
                                <Button fullWidth variant="contained" onClick={() => { setDrawerConnectOpen(false); setDrawerLoginOpen(true) }}><p>{t('button.login')}</p></Button>
                                <div style={{ color: theme.palette.textColor }}>
                                    <p className='txt-center fs-14'>{t('login.noAccount')}
                                        <Link to="/register" underline='none' component={RouterLink} state={{ from: storePaths(location) }}>
                                        <span className='cl-theme pointer m-l-8' onClick={() => { setDrawerConnectOpen(false); setDrawerRegisterOpen(true) }}>{t('button.create')}</span>
                                        </Link>
                                    </p>
                                </div> */}
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('login.connectWalletDisclaimer')}</Typography>
                            </div>

                        </div>
                    </div>
                </Drawer>

                {/* Login Drawer */}
                <Drawer
                    anchor="bottom"
                    open={drawerLoginOpen}
                    onClose={() => setDrawerLoginOpen(false)}
                >
                    <div className='pos-relative' style={{ width: 'auto', padding: '20px', height: '80vh', overflowY: 'auto' }}>
                        <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                            <div className='flex-m w-full clwhite pointer' onClick={() => { setDrawerLoginOpen(false); setDrawerConnectOpen(true) }}>
                                <FiArrowLeft className='fs-icon' />
                                <p className='w-full fs-16 txt-center' style={{ marginLeft: -20 }}>{t('title.login')}</p>
                            </div>
                        </div>
                        <div className='h-full' >
                            <Login />
                            <div className='p-t-24' style={{ color: theme.palette.textColor }}>
                                <p className='txt-center fs-14'>{t('login.noAccount')}
                                    {/* <Link to="/register" underline='none' component={RouterLink} state={{ from: storePaths(location) }}> */}
                                    <span className='cl-theme pointer m-l-8' onClick={() => { setDrawerLoginOpen(false); setDrawerRegisterOpen(true) }}>{t('button.create')}</span>
                                    {/* </Link> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </Drawer>

                {/* Register Drawer */}
                <Drawer
                    anchor="bottom"
                    open={drawerRegisterOpen}
                    onClose={() => { setDrawerRegisterOpen(false); setDrawerConnectOpen(true) }}
                >
                    <div className='pos-relative' style={{ width: 'auto', margin: '20px', height: '80vh', overflowY: 'auto' }}>
                        <div className='flex-sb-m p-b-20' style={{ background: theme.palette.backgroundColor, color: theme.palette.textColor, position: 'sticky', top: 0, zIndex: 2000 }}>
                            <div className='flex-m w-full clwhite pointer' onClick={() => { setDrawerRegisterOpen(false); setDrawerConnectOpen(true) }}>
                                <FiArrowLeft className='fs-icon' />
                                <p className='w-full fs-16 txt-center' style={{ marginLeft: -20 }}>{t('title.register')}</p>
                            </div>
                        </div>
                        <div className='h-full' >
                            <Register />
                            <div className='p-tb-24' style={{ color: theme.palette.textColor }}>
                                <p className='txt-center fs-14' onClick={() => { setDrawerRegisterOpen(false); setDrawerLoginOpen(true) }}>{t('login.haveAccount')}
                                    <span className='cl-theme pointer m-l-8'>{t('button.login')}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </Drawer>

                <DrawerMenu drawerOpen={drawerMenuOpen} setDrawerOpen={setDrawerMenuOpen} logout={logout}/>
            </div>
    )
});

const useStyles = makeStyles((theme) => ({
}));

export default TitleBar;