import { WalletConnect } from '@layouts/WalletConnect';
import { Button, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';



export default function Withdrawal(props) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const [refresh, setRefresh] = useState({});
    let { id } = useParams();
    const { modal } = WalletConnect();

    const [state, setState] = useState({
        wallet: [],
        rule: [],
        amount: '',
        wallet_id: id,
    })
    const [inputErrors, setInputErrors] = useState({});

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'amount') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            setState({ ...state, [name]: value });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    useEffect(() => {
        setLoading(true);
        setInputErrors();
        getUrl(`/wallets/setting/withdrawal/${id}`).then(response => {
            if (response.status) {
                setState({ ...state, wallet: response.data.balance, rule: response.data.rule[id] });
            }else{
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [refresh, id]);

    const setMaxAmount = () => {
        let max = 0;
        max = state.wallet.balance;

        setState({ ...state, amount: Math.floor(max * 100) / 100 });
    }

    const displayTransactionFee = () => {
        let type = state?.rule.charges_type;
        let text = "";
        if(type === "both"){
            text =  state.rule.fee_percent + "% + " + state.rule.fee_amount + " " +state.wallet.code.toUpperCase();
        }else if(type === "percent"){
            text =  state.rule.fee_percent + "%";
        }else if(type === "amount"){
            text =  state.rule.fee_amount + " " +state.wallet.code.toUpperCase();
        }else if(type === "whichever_higher"){
            if(state.amount > 0){
                let fee = state.amount*state.rule.fee_percent/100;
                if(fee > state.rule.fee_amount){
                    text = state.rule.fee_percent + "%";
                }else{
                    text = state.rule.fee_amount + " " +state.wallet.code.toUpperCase();
                }
            }else{
                text = state.rule.fee_amount +" " +state.wallet.code.toUpperCase();
            }
        }
        return text;
    }

    const submit = async (event) => {
        setLoading(true);
        event.preventDefault();
        setInputErrors();
        let params = {
            wallet_id: id,
            amount: state.amount,
        }
        postUrl(`/wallets/withdrawal`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, amount: ''});
                addAlert('', t('success.withdrawSuccess'), 'success', '');
                setRefresh(!refresh);
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.withdrawError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='app-header-margin'>
            <div className='flex-col flex-c-m p-b-30'>
                <p className='fs-36' style={{ fontWeight: 600 }}>{t('title.withdrawals') }</p>
            </div>
            <div className='p-all-20 flex-col-c bor20' style={{ background: '#FFFFFF' }}>
                <div className="w-full flex-col p-t-20">
                    <div className='w-full'>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='amount'
                            type="number"
                            placeholder={t('swaps.tokenAmount', { token: state?.wallet?.code?.toUpperCase() })}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            value={state.amount}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start" className='flex-c-m p-tb-24 p-lr-10' sx={{ borderRight: '1px solid #000A3D24', width: '80px !important' }}>
                                        <p className='txt-upper fs-14' style={{ color: '#00A3FF' }}>{state.wallet.code}</p>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" className='flex-c-m p-r-10' >
                                        <Button variant='standard' className='fs-12' sx={{ borderRadius: '6px', background: '#D2EFFF !important', color: theme.palette.button.main }} onClick={() => setMaxAmount()}>
                                            {t('swaps.max')}
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                height: 72,
                                input: {
                                    fontSize: '14px',
                                    height: '72px !important',
                                    color: '#5E5E5E'
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='p-t-20 w-full'>
                    {
                        !accessToken ?
                            <Button variant='contained' fullWidth style={{ borderRadius: 16, minHeight: 56 }} sx={{ padding: '20px 0 20px 0' }} onClick={() => modal.open()}><p className='flex-m txt-title fs-18'>{t('general.connectWallet')}</p></Button>
                            :
                            <Button variant='contained' fullWidth disabled={state.amount <= 0} sx={{ padding: '20px 0 20px 0' }} onClick={submit}><p >{t('wallet.withdraw')}</p></Button>
                    }
                </div>
                <div className='w-full flex-sb-m p-t-15 fs-12' style={{ color: '#7A8AA0' }}>
                    <p>{t('withdraw.transactionCost')}</p>
                    {state.wallet && <p className='txt-upper'>{displayTransactionFee()}</p>}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));