import React, { useEffect, useRef, useState } from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { Avatar, Card, CardContent, Grid, IconButton, Tooltip, TextField, InputAdornment } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import _ from 'lodash';
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import theme from '../../Theme';
import { currencyFormat } from '@utils/Tools';

import { BiSearch } from "react-icons/bi";
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BackIcon from '@mui/icons-material/ReplyAll';
import { FiSearch, FiChevronRight, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { CiStar } from "react-icons/ci";
import { HiUsers } from "react-icons/hi";


export default function GenealogySponsor(props) {
    const { t, i18n } = useTranslation();
    const { id } = useSelector(state => state.user);
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const { username } = useSelector(state => state.user);

    const [openSearch, setOpenSearch] = useState(false);
    const [tree, setTree] = useState([]);
    const [expandMore, setExpandMore] = useState({});
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '', uplines: [] });

    useEffect(() => {
        isMountedRef.current = true;
        setTree(props.state.data);
        setState({ ...state, username: props.state.data.username });
        let expandStateArr = {};
        expandStateArr[props.state.data.id] = { id: props.state.data.id, expand: true };
        _.map(props.state.data.children, node => (
            expandStateArr[node.id] = { id: node.id, expand: false }
        ))
        setExpandMore(expandStateArr);
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [props.state])

    useEffect(() => {
        searchUsername()
    }, [])

    const searchUsername = (targetUser) => {
        setLoading(true);
        getUrl(`/sponsor-genealogy`, { username: targetUser ? targetUser : props.state.data.username }).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;
                if (status) {
                    setState({ ...state, username: '', uplines: data.upline });
                    setTree(data.tree_data);
                    let expandStateArr = {};
                    if (props.state.data.username) {
                        expandStateArr[data.tree_data.id] = { id: data.tree_data.id, expand: true };
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    } else {
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    }
                    setExpandMore(expandStateArr);
                    setOpenSearch(false);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
            setLoading(false);
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }
        });
    }

    const handleExpand = (id) => {
        setExpandMore({ ...expandMore, [id]: { ...expandMore.id, expand: !expandMore[id].expand } });
    }


    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={`${nodes.id}`}
            className={`${_.size(nodes.children) > 0 ? '' : styles.disabledCursor}`}
            onClick={() => handleExpand(nodes.id)}
            disabled={_.size(nodes.children) > 0 ? false : true}
            id="sponsor"
            label={
                <div >
                    <div>
                        <div className='bor10 p-tb-10 p-lr-16 w-full flex-sb-m' style={{ border: '1px solid #242424' }}>
                            <div className='flex-m' style={{ width: 'calc(100% - 110px)' }}>
                                <div className='bor48 flex-c-m rank-active fs-18' style={{ width: 50, height: 50, minWidth: 50 }}>
                                    <p>{(nodes.rank_code || nodes.crank_code) ? (nodes.rank_code > nodes.crank_code ? JSON.parse(nodes.rank_name)[i18n.resolvedLanguage || 'en'] : JSON.parse(nodes.crank_name)[i18n.resolvedLanguage || 'en']) : '-'}</p>
                                </div>
                                <div className='p-l-10 w-full'>
                                    <p className='fs-14 p-b-8 txt-truncate'>{nodes.username}</p>
                                    <div className='flex-m'>
                                        {/* <div className='flex-m p-lr-10 p-tb-5 bg-text bor5 fs-12 m-r-10' style={{ color: theme.palette.textColor, width: 'fit-content' }}>
                                            <CiStar />
                                            <p className='p-l-5'>1/3</p>
                                        </div> */}
                                        <div className='flex-m p-lr-10 p-tb-5 bor5 fs-12' style={{ color: theme.palette.textColor, width: 'fit-content' }}>
                                            <HiUsers />
                                            <p className='p-l-5'>{nodes.total_direct_downline}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className={`cl-theme pointer fs-14 ${_.size(nodes.children) > 0 ? 'cl-active pointer' : 'cl-inactive'} `} onClick={() => searchUsername(nodes.username)}>{t('button.view')}</p>

                        </div>
                    </div>
                </div>
            }
        >
            {/* {Array.isArray(nodes.children) ? nodes.children.map((node) => renderSubTree(node)) : null} */}
        </TreeItem>
    );

    const renderSubTree = (nodes) => (
        <div key={nodes.id}>
            <div className="divider-white m-tb-10"></div>
            <div>
                <p className={styles.subline}>{t('general.member')}</p>
            </div>
            <Grid container className='flex-sb-m p-t-10'>
                <Grid item xs={12} sm={4}>
                    <div>
                        <p className={`${styles.downlineMemberUsername} txt-truncate flex-m`}>{nodes.username}
                            {
                                (nodes.rank_code || nodes.crank_code) &&
                                <img src={`/images/rank/${nodes.rank_code > nodes.crank_code ? nodes.rank_code : nodes.crank_code}.png`} alt='rank' className='p-l-5' style={{ height: 25, width: 'auto' }} />
                            }
                        </p>
                        {/* <Typography variant='body1' className='txt-truncate ' style={{ color: '#667085' }}>Total Package: 0</Typography> */}
                    </div>
                </Grid>
                <Grid item xs={12} sm={8} style={{ paddingRight: 5, paddingTop: 5 }}>
                    <div className='w-full flex-m' >
                        {
                            nodes.total_downline > 0 ?
                                <div className='flex-r-m p-l-15' style={{ width: 30 }} >
                                    <div className='pointer' onClick={() => searchUsername(nodes.username)}>
                                        <FiChevronDown className='cl-theme fs-15' />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <p className='fs-12 txt-upper p-t-5'>{t('stake.individual')} {currencyFormat(nodes.personal_sales, 2)}</p>
                </Grid>
                <Grid item xs={12}>
                    <p className='fs-12 txt-upper'>{t('stake.group')} {currencyFormat(nodes.group_sales, 2)}</p>
                    <div className='txt-right w-full' >
                        <p className='txt-truncate cl-label fs-12' style={{ fontWeight: 500 }}>{t('genealogy.dateJoined', { 'date': moment(nodes.user_created_at).format('DD/MM/YYYY') })}</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <div>
            <div className='w-full flex-col p-t-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                <TextField
                    variant='standard'
                    InputLabelProps={{ shrink: true }}
                    name="search"
                    placeholder={t('genealogy.searchName')}
                    fullWidth
                    value={state?.username || ''}
                    onChange={(event) => setState({ ...state, username: event.target.value })}
                    onKeyDown={(e) => (e.keyCode == 13 && searchUsername(state.username))}
                    // sx={{ '& .MuiInputBase-root': { marginTop: '0px !important', height: 40 } }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start" style={{ paddingLeft: 20 }}>
                                <IconButton
                                    onClick={() => searchUsername(state.username)}
                                    edge="start"
                                >
                                    <FiSearch className='fs-icon' style={{ color: theme.palette.textColor }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            {/* Breadcrumbs */}
            <div className='flex-m downlinelist fs-14 p-tb-20' style={{ color: theme.palette.textColor }}>
                <p className='pointer' onClick={() => searchUsername()}>{t('genealogy.you')}</p>
                {
                    _.map(state.uplines, (upline, index) => {
                        return (
                            upline.username != username &&
                            <p key={index}>
                                <FiChevronRight className='m-lr-5 fs-14' style={{ color: theme.palette.textColor, verticalAlign: 'bottom' }} />
                                <span className='pointer' onClick={() => searchUsername(upline.username)}>{upline.username}</span>
                            </p>
                        )
                    })
                }
                {
                    tree.username != username &&
                    <p>
                        <FiChevronRight className='m-lr-5 fs-14' style={{ color: theme.palette.textColor, verticalAlign: 'bottom' }} />
                        <span className='pointer' onClick={() => searchUsername(tree.username)}>{tree.username}</span>
                    </p>
                }
            </div>
            {
                (_.size(tree?.children) > 0) ?
                    <>

                        <TreeView
                            className={styles.tree}
                            // defaultExpanded={[props.state.data.username ? `${tree?.id}` : `${id}`]}
                            style={{ position: 'relative', zIndex: 0 }}
                        >
                            {/* {props.state.data.username && tree.id !== id ? renderTree(tree) : _.map(tree.children, node => (renderTree(node)))} */}
                            {_.map(tree.children, node => (renderTree(node)))}
                        </TreeView>
                    </>
                    :
                    <Card style={{ border: 'none', background: 'transparent' }} variant="outlined">
                        <CardContent className='txt-center' style={{ height: 400, borderRadius: 0 }}>
                            <div className='p-t-40'>
                                {/* <img src='/images/general/no-transaction.png' alt='no-genealogy' style={{ width: 180 }} loading='lazy' /> */}
                            </div>
                            <p className='txt-white fs-18 p-t-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('genealogy.noRecord')}</p>
                            <p className='txt-white fs-14 p-t-8' style={{ color: '#D0D5DD' }}>{t('genealogy.noRecordDetails')}</p>
                        </CardContent>
                    </Card>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    tree: {
        flexGrow: 1,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        color: '#c1c1c1',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10,
        fontStyle: 'italic'
    },
    downlineBoxDesign: {
        position: 'relative',
        marginBottom: 15,
        padding: '15px 15px 15px 0px',
        borderRadius: 15,
        backgroundColor: '#ffffff1f',
        boxShadow: 'inset 1px 1px 1px 0 #fff3, inset -2px -2px 1px 0 #dbfffb47'
    },
    subline: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '-0.16px',
        color: '#98A2B3',
        fontFamily: 'sans-serif'
    },
    downlineMemberUsername: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#FFF'
    },
    disabledCursor: {
        cursor: 'default',
        pointerEvents: 'none'
    }
}));