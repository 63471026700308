import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'qrcode';

import "@css/tree.css";
import { Drawer, Button, TextField, InputAdornment, IconButton, Dialog, Grid, Tooltip, Breadcrumbs, Stack, setRef } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import GenealogySponsor from './GenealogySponsor';
import { currencyFormat } from '@utils/Tools';
import { ethers } from 'ethers';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import GeneologyDialog from '@components/GeneologyDialog';
import { WalletConnect } from '@layouts/WalletConnect';

import { MdClose, MdDownload, MdQrCode } from 'react-icons/md';
import DoneIcon from '@mui/icons-material/Done';
import { FaLock } from "react-icons/fa6";
import { LuCopy, LuUsers2, LuDot } from "react-icons/lu";
import { FiX, FiSearch, FiArrowRight, FiCheck, FiChevronRight } from "react-icons/fi";
import { GrShare } from "react-icons/gr";
import { GoChevronDown } from 'react-icons/go';

const SWAP_CONTRACT_TYPE = { // FOR activatePackage
    'usdt': 'jfu',
    'jfu': 'jfu',
    'jfa': 'jfa',
}

export default function Index(props) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let { page } = useParams();
    const shareLink = useRef(null);
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const { username, web_three } = useSelector(state => state.user);
    const { ABI, chain, chainId, provider, hexChainId } = WalletConnect();
    const { walletProvider } = useWeb3ModalProvider();

    const [drawerDownlineOpen, setDrawerDownlineOpen] = useState(false);
    const [drawerActivateOpen, setDrawerActivateOpen] = useState(false);
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'sponsor',
        totalDirectDownlines: 0,
        totalDownlines: 0,
        data: [],
        ranks: [],
        currentRank: '',
        nextRank: '',
        downlinePlanCount: 0,
        qualification: false,
        step: 1,
        contractAddress: [],
        combination: [],
        tokenList: [],
        token: '',
        userPlan: []
    });
    const [geneologyDialog, setGeneologyDialog] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleClickOpenGeneologyDialog = () => {
        setGeneologyDialog(true);
    };
    const handleCloseGeneologyDialog = () => {
        setGeneologyDialog(false);
    };

    useEffect(() => {
        if (page) {
            if (_.includes(['sponsor', 'placement'], page)) {
                setState({
                    ...state,
                    pageIndex: page === 'placement' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    // useEffect(() => {
    //     if (accessToken) {
    //         isMountedRef.current = true;
    //         setLoading(true);
    //         let apiUrl = 'sponsor-genealogy';
    //         getUrl(apiUrl).then(response => {
    //             if (response.status && isMountedRef.current) {
    //                 setState({
    //                     ...state,
    //                     totalDirectDownlines: response.data.tree_data.total_direct_downline,
    //                     totalDownlines: response.data.tree_data.total_downline,
    //                     data: response.data.tree_data
    //                 });
    //             }
    //         }).catch(error => {
    //             addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //         }).finally(() => {
    //             setLoading(false);
    //         });
    //         // }
    //         setLoading(false);
    //         return () => isMountedRef.current = false;
    //     }
    // }, [accessToken]);

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('general.copied'), "success");
            }).catch((error) => {
                addAlert('', t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
            setLoading(true);
            getUrl('/community').then(response => {
                if (response.status) {
                    setState({
                        ...state,
                        ranks: response.data.ranks,
                        currentRank: response.data.current_rank,
                        downlinePlanCount: response.data.downlines_plan_histories,
                        qualification: response.data.qualification,
                        nextRank: response.data.next_rank,
                        contractAddress: response.data.contract_address,
                        combination: response.data.payment_combination,
                        tokenList: response.data.activate_method,
                        token: Object.keys(response.data.activate_method || [])[0],
                        userPlan: response.data.user_plan
                    });
                } else {
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [accessToken, refresh])

    const submitActivation = (txid) => {
        setLoading(true);
        let params = {
            package_id: state.nextRank.id,
            combination_id: Object.keys(state.combination.wallet)[0],
            txid: txid,
            token: state.token,
        }
        postUrl(`purchase-plan`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setRefresh(!refresh);
                setState({ ...state, step: 2 });
            } else {
                let message = '';
                if (_.size(response.errors) > 0) {
                    _.map(response.errors, (value, key) => {
                        message += "\n " + value[0];
                    })
                }
                addAlert('', message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const web3SubmitActivation = (txid) => {
        setLoading(true);
        let params = {
            package_id: state.nextRank.id,
            txid: txid,
            token: state.token,
        }
        postUrl(`web3/purchase-plan`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setRefresh(!refresh);
                setState({ ...state, step: 2 });
            } else {
                let message = '';
                if (_.size(response.errors) > 0) {
                    _.map(response.errors, (value, key) => {
                        message += "\n " + value[0];
                    })
                }
                addAlert('', message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const activatePackage = async (contractAddress, abi) => {
        const provider = walletProvider ? new ethers.BrowserProvider(walletProvider) : new ethers.BrowserProvider(window.ethereum);
        if (provider) {
            setLoading(true);
            // change chain if not connected to correct chain
            if (chain['id'] != chainId) {
                try {
                    await provider.send("wallet_switchEthereumChain", [{ chainId: hexChainId }]);
                    setLoading(false);
                } catch (error) {
                    addAlert('', `Please change your wallet connect network to ${chain['name']}`, 'error', '');
                    setLoading(false);
                    return;
                }
            }
            if (!state.nextRank?.price) {
                setLoading(false);
                addAlert('', t('error.contactSupport'), 'error', '');
                return;
            }
            try {
                setLoading(true);
                let signer = await provider.getSigner();
                let contract = new ethers.Contract(contractAddress, abi, signer);
                let tx;
                switch (state.token) {
                    case 'usdt':
                        // check allowance
                        const response = await getUrl(`/wallets/allowance/usdt-jfu`);
                        if (response.status) {
                            if (Number(state.nextRank.price) <= Number(response.data)) {
                                tx = await contract.activatePackageByUSDT(ethers.parseUnits(state.nextRank.price));
                            } else {
                                let contractUSDT = new ethers.Contract(state.contractAddress['usdt'], abi, signer);
                                let approveTx = await contractUSDT.approve(state.contractAddress['jfu'], ethers.MaxUint256);
                                let approveReceipt = await provider.waitForTransaction(approveTx.hash);
                                if (approveReceipt.status) {
                                    tx = await contract.activatePackageByUSDT(ethers.parseUnits(state.nextRank.price));
                                }
                            }
                        } else {
                            setLoading(false);
                            addAlert('', response.message || t('error.contactSupport'), 'error', '');
                            return true;
                        }
                        break;
                    default:
                        tx = await contract.activatePackage(ethers.parseUnits(state.nextRank.price));
                        break;
                }
                if (tx.hash) {
                    web3SubmitActivation(tx.hash);
                    setLoading(false);
                }
                // let receipt = await provider.waitForTransaction(tx.hash);
                // if (receipt.status) {
                //     // console.log("contract activate package Success");
                //     submitActivation(tx.hash); // submit backend
                // } else {
                //     setLoading(false);
                //     addAlert('', t('error.contactSupport'), 'error', '');
                //     // console.log("contract activate package Failed", receipt);
                // }
            } catch (error) {
                setLoading(false);
                const revertReason = (error.reason || error.message) || t('error.transactionError');
                addAlert('', revertReason, 'error', '');
                // console.log("contract activate package Failed", error);
            }
        } else {
            addAlert('', 'Wallet Connect is not installed', 'error', '');
        }
    }

    return (
        <div className='p-t-110 p-lr-20' style={{ minHeight: '100vh' }}>
            {
                !accessToken ? // not logged in
                    <div className='p-t-20'>
                        <div className='w-full flex-c-m' style={{ borderRadius: 16, height: 180, border: '1px solid #242424' }}>
                            <p className='fs-16' style={{ fontWeight: 500, width: '60%', textAlign: 'center' }}>{t('genealogy.noAccess')}</p>
                        </div>
                        {/* <div className='p-t-20'>
                            <Button className='w-full txt-title'>{t('general.connectWallet')}</Button>
                        </div> */}
                    </div>
                    : // logged in
                    <div className='p-t-20'>
                        {/* <div className='flex-sb-m w-full'>
                            <p className='fs-14 clwhite'>{t('genealogy.tierProgress')}</p>
                            <div className='flex-sb-m' style={{ width: 200, maxWidth: '60%' }}>
                                {
                                    _.map(state.ranks, rank => {
                                        return (
                                            <div key={rank.id} className={`flex-c-m bor48 p-all-6 fs-14 ${state.currentRank?.code >= rank.code ? 'rank-active' : 'rank-inactive'}`} style={{ width: 30, height: 30 }}>
                                                <p>{rank.name[i18n.resolvedLanguage || 'en'] || '-'}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
                        <div className='p-t-20'>
                            <div className='w-full pos-relative' style={{ border: '1px solid #242424', borderRadius: 16 }}>
                                <div className='pos-absolute' style={{ right: 20, top: 20 }}>
                                    {/* <p className='clwhite fs-12 p-l-25'>{t('genealogy.vipLevel')}</p> */}
                                    <LuUsers2 className='pointer fs-icon cl-theme' onClick={() => { setDrawerDownlineOpen(true) }} />
                                </div>
                                <div className='flex-c-m'>
                                    <div className='p-all-15' style={{ width: 150 }}>
                                        <img src={`/images/rank/${state.currentRank ? state.currentRank.code : 'inactive_rank'}.png`} alt="rank" className='w-full' />
                                    </div>
                                    {/* <div style={{height: 89, width: 93,}}>
                                        <div className="flex-c centeredText p-t-10 fs-46" style={{ color: '#FFFFFF', fontWeight: 600 }}>{state.currentRank?.name?.[i18n.resolvedLanguage || 'en'] || '-'}</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* temporary hide - no pages */}
                        {/* <div className='flex-c-m p-t-25' style={{ color: theme.palette.primary.main }}>
                            <p className='fs-12 p-r-10'>{t('genealogy.tierBenefits')}</p>
                            <GrShare className='fs-15' style={{ color: theme.palette.primary.main }}/>
                        </div> */}
                        <div className='p-t-20'>
                            <div className="w-full flex-sb-m p-all-20 bg-text bor10" style={{ minHeight: 49 }}>
                                <div className='flex-l fs-14'>
                                    <p style={{ color: 'white' }}>{t('genealogy.referralCode')}</p>
                                </div>
                                <div className='flex-m'>
                                    <p className='p-r-10 fs-14 clwhite'>{username}</p>
                                    <LuCopy className='fs-16 pointer' style={{ color: theme.palette.primary.main }} onClick={() => copyShareLink(username)} />
                                </div>
                            </div>
                        </div>
                        {/* {
                            state.nextRank &&
                            <div>
                                {
                                    state.currentRank &&
                                    <div className='p-t-10'>
                                        <div className='bg-text bor10 p-all-20 fs-12' style={{ color: theme.palette.textColor }}>
                                            <p className='fs-12 p-b-10 clwhite'>{t('genealogy.upgradePrerequisite', { 'rank': state.nextRank?.name?.[i18n.resolvedLanguage || 'en'] || '-' })}</p>
                                            <div className='flex-sb-m'>
                                                <div className='flex-m fs-12 p-r-10'>
                                                    <LuDot className="fs-16" />
                                                    <p className='p-l-5'>{t('genealogy.prerequisiteList', { 'rank': state.currentRank?.name?.[i18n.resolvedLanguage || 'en'] || '-' })}</p>
                                                </div>
                                                <p><span className='cl-theme'>{state.downlinePlanCount}</span>/3</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    _.size(state.tokenList) > 1 &&
                                    <div className='p-t-10'>
                                        <div className='flex-sb-m bor10 pointer p-all-20' style={{ background: '#1B1B1B' }} onClick={() => setDrawerOpen(true)}>
                                            <div>
                                                <p className='fs-14' style={{ color: theme.palette.textColor }}>{t('package.paymentMethod')}</p>
                                            </div>
                                            <div className='clwhite flex-r-m'>
                                                <img src={`/images/wallet/token-${state.token}.png`} alt="pay-token" style={{ width: 20, aspectRatio: 1 }} />
                                                <p className='txt-upper fs-14 m-l-5'>{state.token}</p>
                                                <GoChevronDown className='m-l-10 cl-theme' />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='p-t-10'>
                                    <Button disabled={!state.qualification} variant='contained' fullWidth onClick={() => setDrawerActivateOpen(true)} >
                                        <div className='flex-m'>
                                            <p className='p-r-10'>{state.userPlan?.status == 10 ? t('button.pendingConfirmation') : (state.qualification ? t('button.activate') : t('genealogy.locked'))}</p>
                                            {state.qualification ?
                                                <div className='flex-m'>
                                                    <img src={`/images/wallet/token-${state.token}.png`} alt="wallt" style={{ width: 21 }} />
                                                    <p className='p-l-10 clwhite'>{currencyFormat(state.nextRank?.price || 0, 2)}</p>
                                                </div>
                                                :
                                                <FaLock className='fs-icon' />
                                            }
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        } */}
                    </div>
            }

            {/* Search Member Drawer */}
            <Drawer
                anchor="bottom"
                open={drawerDownlineOpen}
                onClose={() => setDrawerDownlineOpen(false)}
            >
                <div className='p-b-40' style={{ width: 'auto', padding: '20px', height: '80vh', overflowY: 'auto' }}>
                    <div className='flex-sb-m p-b-20' style={{ color: theme.palette.textColor }}>
                        <div className='flex-m w-full clwhite'>
                            <p className='w-full fs-16 txt-title txt-center' style={{ marginRight: -20 }}>{t('general.member')}</p>
                            <FiX className='fs-icon pointer' onClick={() => setDrawerDownlineOpen(false)} />
                        </div>
                    </div>
                    <GenealogySponsor state={state} />
                </div>
            </Drawer>

            {/* Activate Drawer */}
            <Drawer
                anchor="bottom"
                open={drawerActivateOpen}
                onClose={() => { setDrawerActivateOpen(false); setState({ ...state, step: 1 }) }}
            >
                <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center' }}>
                    {/* Activate Rank */}
                    {
                        state.step == 1 &&
                        <div>
                            <div className='flex-sb-m p-b-20 clwhite p-t-10'>
                                <p className='fs-16 txt-title'>{t('genealogy.confirmActivate')}</p>
                                <FiX className='fs-icon pointer' onClick={() => setDrawerActivateOpen(false)} />
                            </div>
                            <div className='w-full flex-col p-tb-20 fs-14' style={{ gap: 24, color: theme.palette.textColor }}>
                                <div className='flex-c-m w-full'>
                                    <div style={{ width: '40%' }}>
                                        <img src="/images/rank/inactive_rank.png" alt="rank" />
                                    </div>
                                    <FiArrowRight className='fs-icon clwhite m-lr-20' />
                                    <div style={{ width: '40%' }}>
                                        <img src={`/images/rank/${state.nextRank?.rank?.code}.png`} alt="rank" />
                                    </div>
                                </div>
                                <div className='flex-sb-m w-full clwhite'>
                                    <p>{t('genealogy.youArePaying')}</p>
                                    <div className='flex-m'>
                                        <img src={`/images/wallet/token-${state.token}.png`} alt="wallet" style={{ width: 20 }} />
                                        <p className='p-l-10'>{currencyFormat(state.nextRank?.price || 0, 2)}</p>
                                    </div>
                                </div>
                            </div>
                            <Button variant='contained' fullWidth onClick={() => web_three ? activatePackage(state.contractAddress[SWAP_CONTRACT_TYPE[state.token]], ABI[SWAP_CONTRACT_TYPE[state.token]]) : submitActivation('')}>
                                <p className='p-r-10'>{t('button.approveNActivate')}</p>
                            </Button>
                        </div>
                    }
                    {/* Success */}
                    {
                        state.step == 2 &&
                        <div>
                            <div className='p-b-40 p-t-20' style={{ width: '70%', margin: '0 auto' }}>
                                <div className='flex-c-m bor48' style={{ width: 80, height: 80, margin: '0 auto', border: '1px solid', borderColor: theme.palette.primary.main }}>
                                    <FiCheck className='cl-theme fs-40' />
                                </div>
                                <p className='fs-18 p-t-20 clwhite lh-16'>{web_three ? t('genealogy.web3SuccessMessage') : t('genealogy.successMessage')}</p>
                            </div>

                            <Button variant='contained' fullWidth onClick={() => setDrawerActivateOpen(false)}>
                                <p className='p-r-10'>{t('button.continue')}</p>
                            </Button>
                        </div>
                    }
                </div>
            </Drawer>
            {/* select  */}
            <Drawer
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center' }}>
                    <div className='flex-sb-m'>
                        <p className='clwhite fs-16'>{t('package.paymentMethod')}</p>
                        <MdClose className='pointer' style={{ width: 24, height: 24 }} onClick={() => setDrawerOpen(false)} />
                    </div>
                    <div className='p-t-20 p-b-50'>
                        {
                            _.map(state.tokenList, (data, key) => {
                                return (
                                    <div key={key} className='flex-sa-m m-b-10 bor10 pointer' style={{ border: `1px solid ${key == state.swap ? '#5E5E5E' : '#242424'}`, padding: '10px 16px 10px 16px' }} onClick={() => { setState({ ...state, token: key }); setDrawerOpen(false) }}>
                                        <div className='flex-m'>
                                            <img src={`/images/wallet/token-${key}.png`} alt="pay-token" style={{ width: 32, aspectRatio: 1 }} />
                                            <div className='flex-col-l m-l-10'>
                                                <p className='clwhite fs-14 txt-upper'>{key}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Drawer>

            {/* Downline */}
            {/* <div >
                <div className="p-lr-20 flex-sb-m" style={{ gap: 8 }}>
                    <div>
                        <p className={styles.subline}>{t('genealogy.member')}</p>
                        <p className={styles.subline2}>{t('genealogy.memberTotal', { 'amount': state.totalDownlines })}</p>
                    </div>
         
                    <div>
                        <MdQrCode className='txt-white fs-35 pointer' onClick={handleClickOpenGeneologyDialog} />
                    </div>
                </div>
                <div>
                    <GenealogySponsor state={state} />
                </div>
            </div>
            <GeneologyDialog dialog={geneologyDialog} handleCloseDialog={handleCloseGeneologyDialog} /> */}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    imageContainer: {
        position: 'relative',
        display: 'inline-block',
    },
    centeredImage: {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
    },
    centeredText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontSize: '24px',
        fontWeight: 'bold',
        textAlign: 'center',
    }

}));
