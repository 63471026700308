import React, { useEffect, useState } from 'react';
import { Button, Drawer, SwipeableDrawer, List, ListItem, ListItemButton, ListItemText, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storePaths } from '@utils/Tools';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// import { loginGame, postUrl } from '@utils/ApiAction';
import _ from 'lodash';

const NAVIGATOR = { 'hubPlans': '/', 'swapMKA': '/swap/mka', 'swapMKC': '/swap/mkc', 'withdrawalsBP': '/wallet/withdrawal/2', 'withdrawalsCW': '/wallet/withdrawal/5', 'withdrawalsMKC': '/wallet/withdrawal/4', 'community': '/community'  };

const DrawerMenu = ({ drawerOpen, setDrawerOpen, logout }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    let location = useLocation();
    let current_path = location?.pathname;
    let navigate = useNavigate();

    const goTo = (destination) => {
        navigate(destination, { state: { from: storePaths(location) } });
    }

    return (
        <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            onOpen={() => setDrawerOpen(true)}
        >
            <div className='p-b-40 flex-col' style={{ padding: '20px', textAlign: 'center', backgroundColor: '#F1F5F6', height: '100%' }}>
                <div className='flex-sb-m p-b-20'>
                    <p></p>
                    <MdClose className='fs-24 pointer' onClick={() => setDrawerOpen(false)} />
                </div>
                <List style={{ flexGrow: 1 }}>
                    {
                        _.map(NAVIGATOR, (link, label) => (
                            <ListItem key={label} disablePadding style={{ backgroundColor: 'white', marginBottom: 10, borderRadius: 10, overflow: 'hidden' }}>
                                {current_path === link && <div style={{ width: 4, height: '100%', backgroundColor: '#00A3FF', position: 'absolute' }}></div>}
                                <ListItemButton onClick={() => goTo(link)} style={{ padding: '10px 20px'}}>
                                    <ListItemText primary={t(`title.${label}`)} style={{ textTransform: 'capitalize', margin: 0 }} sx={[{ 'span':{fontSize: 14, lineHeight: "20px" }}]}/>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
                {
                    accessToken &&
                    <Button fullWidth variant="contained" sx={{ padding: '10px 0 10px 0' }} onClick={() => logout()} >{t('button.logout')}</Button>
                }
            </div>
        </SwipeableDrawer>
    )
}

export default DrawerMenu;