import { useEffect, useState } from 'react';
import { WalletConnect } from '@layouts/WalletConnect';
import { Button, InputAdornment, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { ethers } from 'ethers';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FaArrowDown } from "react-icons/fa6";


export default function Index(props) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const { username, email, crypto_address, web_three } = useSelector(state => state.user);
    const { ABI, chain, chainId, provider, hexChainId, modal } = WalletConnect();
    const { walletProvider } = useWeb3ModalProvider();
    let { wallet } = useParams();

    const [info, setInfo] = useState({
        balances: [],
        allowances: [],
        rules: [],
        companyAddress: '',
        contractAddress: '',
        rate: 0,
    });
    const [state, setState] = useState({
        pay: '',
        receive: 0,
        swap: wallet,
    })
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const explorerLink = process.env.NODE_ENV == 'development' ? 'https://testnet.bscscan.com/tx/' : 'https://bscscan.com/tx/';

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'pay') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            let receive = value / info.rate;
            setState({ ...state, [name]: value, receive: receive });
        } else if (name == 'receive') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            let pay = value * info.rate;
            setState({ ...state, [name]: value, pay: pay });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    useEffect(() => {
        // setState({ ...state, receive: state.pay / info.rate });
        if (Number(state.pay || 0) > Number(info.balances['usdt'] || 0) || state.pay < state.rules?.min_amount) {
            setInsufficientBalance(true);
        } else {
            setInsufficientBalance(false);
        }
        // eslint-disable-next-line
    }, [state.swap, state.pay, state.rules, info])

    useEffect(() => {
        setLoading(true);
        getUrl('/wallets/swap-setting', { wallet: wallet }).then(response => {
            if (response.status) {
                setInfo({
                    ...info,
                    balances: response.data?.balances,
                    allowances: response.data?.allowances,
                    rules: response.data?.deposit_rules,
                    companyAddress: response.data?.company_address,
                    contractAddress: response.data?.contract_address,
                    rate: response.data?.rate
                });
            } else {
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [accessToken, wallet])

    const setMaxAmount = (wallet) => {
        let max = 0;
        let maxReceive = 0;
        if (wallet != 'usdt') {
            max = info.balances['usdt'];
            maxReceive = info.balances['usdt'] / info.rate;
        } else {
            max = info.balances[wallet];
            maxReceive = info.balances[wallet] / info.rate;
        }
        setState({ ...state, pay: Math.floor(max * 100) / 100, receive: Math.floor(maxReceive * 100) / 100 });
    }

    const submit = async () => {
        setLoading(true);
        const provider = walletProvider ? new ethers.BrowserProvider(walletProvider) : new ethers.BrowserProvider(window.ethereum);
        if (provider /* && info.companyAddress */) {
            const getAllowance = async () => {
                try {
                    const response = await getUrl(`/wallets/allowance/usdt`);
                    if (response.status) {
                        if (Number(state.pay) <= Number(response.data)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        addAlert('', response.message || t('error.contactSupport'), 'error', '');
                        return true;
                    }
                } catch (error) {
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    return true;
                };
            }
            const allowanceResponse = await getAllowance();
            if (!allowanceResponse) { // if not enough allowance do approve action
                // change chain if not connected to correct chain
                if (chain['id'] != chainId) {
                    try {
                        await provider.send("wallet_switchEthereumChain", [{ chainId: hexChainId }]);
                    } catch (error) {
                        addAlert('', `Please change your wallet connect network to ${chain['name']}`, 'error', '');
                        setLoading(false);
                        return;
                    }
                }
                try {
                    let signer = await provider.getSigner();
                    let contract = new ethers.Contract(info.contractAddress, ABI.usdt, signer);
                    let tx = await contract.approve(info.companyAddress, ethers.MaxUint256);
                    let receipt = await provider.waitForTransaction(tx.hash);
                    if (!receipt.status) {
                        addAlert('', t('error.approveAllowanceError'), 'error', '');
                        setLoading(false);
                        return;
                        // console.log("contract approve Failed", receipt);
                    }
                } catch (error) {
                    const revertReason = (error.reason || error.message) || t('error.transactionError');
                    addAlert('', revertReason, 'error', '');
                    setLoading(false);
                    return;
                }
            }
            let params = {
                "wallet_code": wallet,
                "amount": state.receive
            };
            postUrl(`/wallet/swap-token`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    setState({...state, pay: '', receive: 0});
                    addAlert('', t('swaps.successfully'), 'success', '');
                } else {
                    let msg = response.message;
                    _.map(response.errors, (value, index) => {
                        msg += "\n\n " + value;
                    })
                    addAlert('', msg || response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setLoading(false);
            addAlert('', 'Wallet Connect is not installed', 'error', '');
        }
    }

    return (
        <div className='app-header-margin'>
            <div className='flex-col flex-c-m p-b-30'>
                <p className='fs-36' style={{ fontWeight: 600 }}>{wallet == 'mka' ? t('title.swapMKA') : t('title.swapMKC')}</p>
                <p className='fs-16 p-t-10' style={{ color: '#7A8AA0' }}>{t('swaps.buyTokenDesc', { token: wallet.toUpperCase() })}</p>
            </div>
            <div className='p-all-20 flex-col-c bor20' style={{ background: '#FFFFFF' }}>
                <div className="w-full flex-col p-t-20">
                    <div className='w-full'>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='pay'
                            type="number"
                            placeholder={t('swaps.tokenAmount', { token: 'USDT' })}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            value={state.pay}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start" className='flex-c-m p-tb-24 p-lr-10' sx={{ borderRight: '1px solid #000A3D24', width: '80px !important' }}>
                                        <p className='txt-upper fs-14' style={{ color: '#00A3FF' }}>USDT</p>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" className='flex-c-m p-r-10' >
                                        <Button variant='standard' className='fs-12' sx={{ borderRadius: '6px', background: '#D2EFFF !important', color: theme.palette.button.main }} onClick={() => setMaxAmount('usdt')}>
                                            {t('swaps.max')}
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                height: 72,
                                input: {
                                    fontSize: '14px',
                                    height: '72px !important',
                                    color: insufficientBalance ? '#ff2020' : '#5E5E5E'
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex-c-m" style={{ zIndex: 3, background: '#00A3FF', borderRadius: 100, color: '#ffffff' }}>
                    <FaArrowDown className='fs-14 m-all-4' />
                </div>
                {/* <div className="w-full flex-col p-all-20">
                    <div className='flex-sb-m p-t-20'>
                        <p className='fs-30' style={{ color: state.receive > 0 ? '#FFFFFF' : '#5E5E5E' }}>{currencyFormat(state.receive || 0, 2)}</p>
                        <div className='flex-m'>
                            <p className='txt-upper clwhite fs-14 m-l-5'>{type}</p>
                        </div>
                    </div>
                </div> */}
                <div className='w-full p-t-15'>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='receive'
                        type="number"
                        placeholder={t('swaps.tokenAmount', { token: wallet.toUpperCase() })}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        value={state.receive}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start" className='flex-c-m p-tb-24 p-lr-14' sx={{ borderRight: '1px solid #000A3D24' }}>
                                    <p className='txt-upper fs-14' style={{ color: '#00A3FF' }}>{wallet}</p>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" className='flex-c-m p-r-10' >
                                    <Button variant='standard' className='fs-12' sx={{ borderRadius: '6px', background: '#D2EFFF !important', color: theme.palette.button.main }} onClick={() => setMaxAmount(wallet)}>
                                        {t('swaps.max')}
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            height: 72,
                            input: {
                                fontSize: '14px',
                                height: '72px !important',
                                color: insufficientBalance ? '#ff2020' : '#5E5E5E'
                            }
                        }}
                    />
                </div>
                <div className='p-t-20 w-full'>
                    {
                        !accessToken ?
                            <Button variant='contained' fullWidth style={{ borderRadius: 16, minHeight: 56 }} sx={{ padding: '20px 0 20px 0' }} onClick={() => modal.open()}><p className='flex-m txt-title fs-18'>{t('general.connectWallet')}</p></Button>
                            :
                            <Button variant='contained' fullWidth disabled={insufficientBalance || state.pay <= 0} sx={{ padding: '20px 0 20px 0' }} onClick={submit}><p >{t('swaps.swapNow')}</p></Button>
                    }
                </div>
                <div className='w-full flex-sb-m p-t-10 fs-12' style={{ color: '#7A8AA0' }}>
                    <p>{t('swaps.rate')}</p>
                    <p className='txt-upper'>1 USDT = {info.rate ? (1 / info.rate) : 0} {wallet}</p>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));