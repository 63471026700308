import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

let theme = createTheme({
    palette: {
        primary: {
            main: '#00A3FF',
            secondary: '#11211C'
        },
        secondary: {
            main: '##05122D',
            secondary: '#cf8f2e',
        },
        green: {
            main: green[500],
        },
        red: {
            main: red[500],
        },
        gray: {
            main: '#7A8AA0',
            secondary: '#b5b5b5',
            ltext: '#b7b7b7',
            dtext: '#606060',
            light: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
        },
        gold:{
            main: '#FFC21B'
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#eaeaea',
        },
        button: {
            main: '#00A3FF',
            borderMain: '2px solid #CDAC89',
            darkRed: '#cd4949',
            yellow: '#ffcb58',
            green: '#47ba80'
        },
        bottomIconColor: '#ffffff',
        selectedBottomIconColor: 'linear-gradient(90deg, #55D3FF,#684EFF)',
        // backgroundColor: 'radial-gradient(65.21% 41.05% at 50% 50%, #061755 0%, #06071B 100%)',
        backgroundColor: '#F1F5F6',
        white: '#ffffff',
        dialogContentBg: '#0D184F',
        inputIconColor: '#ffffff',
        textColor: '#11162B'
    },
    components: {
        // Name of the component
        MuiBottomNavigation: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // background: 'linear-gradient(180deg, #324499 8.95%, #06071B 91.05%)',
                    background: '#1a1f49',
                    // height:80,
                    // paddingBottom:15,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
                label: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '12vw',
                }
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    left: '8px !important',
                    right: '8px !important',
                }
            }

        },

        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: 15,
                    boxShadow: 'inset 1px 1px 1px 0 #fff3, inset -2px -2px 1px 0 #dbfffb47',
                    borderRadius: '15px',
                    backgroundColor: '#00A3FF',
                    color: '#ffffff',
                    position: 'relative',
                    backdropFilter: 'blur(20px)',
                    width: 375,
                    maxWidth: '100%'
                },
                message: {
                    padding: '30px 10px 20px'
                },
                icon: {
                    color: '#ffffff !important',
                    position: 'absolute',
                    top: 5,
                    left: 20
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        // backgroundColor: '#11211C',
                        // borderColor: '#1DF7AD',
                        // color: '#029363 !important',
                    },
                    whiteSpace: 'pre',
                    // backgroundColor: '#00A3FF',
                    borderRadius: 10,
                    gap: 10,
                    padding: '10px 10px 10px 10px',
                    color: '#FFFFFF',
                    borderColor: '#00A3FF',
                    fontWeight: 600,
                    fontSize: 14
                    // '&:hover': {
                    //     background: '#0CC0DF !important',
                    //     boxShadow: '0px 4px 10px 0px rgba(117, 238, 255, 0.18), -2px -2px 2px 0px rgba(255, 255, 255, 0.44) inset, 3px 3px 2px 0px rgba(0, 0, 0, 0.22) inset'
                    // }
                },
                standard:{
                    padding: '5px 16px',
                    fontSize: 12
                },
                contained: {
                    '&.Mui-disabled': {
                        // backgroundColor: '#11211C',
                        // background: '#11211C',
                        // color: '#029363'
                    },
                    '&:hover': {
                        // color: '#00A3FF !important',
                        background:'#0CC0DF'
                    },
                    textTransform: 'capitalize',
                    // background: '#11211C',
                    backgroundColor: '#00A3FF',
                    color:'#FFFFFF',
                    fontSize:14,
                    borderRadius:10,
                    // boxShadow: '0px 8px 10px 0px rgba(117, 238, 255, 0.24), 0px -4px 7px 0px rgba(27, 143, 150, 0.15) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.45) inset;'
                },
                outlined: {
                    color: '#00A3FF',
                }
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {

                    // Default state of text input field font.
                    // '& .MuiInputBase-input.MuiInput-input': {
                    //     color: '#black',
                    // },

                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        // WebkitTextFillColor: '#1B1B1B',
                        backgroundColor: "transparent"
                    },

                    // '& .MuiInputBase-root': {
                    //     color: '#black',
                    // },

                    // '& .MuiFormLabel-root.Mui-focused': {
                    //     color: '#black',
                    // },

                    '& .MuiFormLabel-root.Mui-disabled': {
                        color: '#ffffff',
                    },

                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl::before': {
                        borderBottomColor: 'none',
                    },

                    // On hover state of underline.
                    // '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl:hover::before': {
                    //     color: '#black',
                    //     border:'none'
                    // },

                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.Mui-focused::after': {
                        borderBottomColor: 'none',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: '#11162B'
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#ffffff',
                    color: '#16114E'
                }
            }
        },
        MuiModal: {
            styleOverrides:{
                backdrop: {
                    width:444,
                    maxWidth:'100%',
                    margin:'0 auto'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    flexShrink: 0,
                    height: 55,
                    // color: '#black',
                    borderRadius: '10px !important',
                    background: '#ffffff',
                    color: '#000000',
                    border: '1px solid #000A3D24',
                    // boxShadow: 'inset 0 1px 2px 0 #ffffff87',
                    // backdropFilter: 'blur(5.5px)',
                    // backdropFilter: 'blur(5.5px)',
                },
                contained: {
                    marginTop: '20px',
                    padding: 16,
                },
                standard: { //* used in swap textfield
                    fontSize: '30px'
                },
                input: {
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#000000',
                    '&:-webkit-autofill': {
                        WebkitTextFillColor: '#ffffff',
                        // height: 7,
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#11162B',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    ":after": {
                        borderTop: "thin solid #ffffff"
                    },
                    ":before": {
                        borderTop: "thin solid #ffffff"
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#202E66",
                    color: '#11162B',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#11162B',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    // borderTop: '2px solid #808080',
                    // borderBottom: '2px solid #808080',
                },
                indicator: {
                    backgroundColor: 'transparent',
                },
                scrollButtons: {
                    color: '#ffffff',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    // borderTop: '2px solid #808080',
                    // borderBottom: '2px solid #808080',
                    color: '#dfdfdf8f',
                    "&.Mui-selected": {
                        borderTop: 'none',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#000000',
                    borderRadius: 8,
                    padding: 8,
                },
                arrow: {
                    color: '#000000',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
                input: {
                    padding:'0 10px',
                    fontWeight:500,
                }
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    // backgroundColor: 'rgba(239, 75, 39, 1)',
                    // background: 'linear-gradient(90deg, #55D3FF,#684EFF)'
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F1F5F6',
                    boxShadow: 'none'
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: 444,
                    maxWidth: '100%',
                    margin: '0 auto',
                    background: '#FFFFFF',
                    borderRadius: '20px 20px 0px 0px',
                    // height: '80vh',
                    // overflowY: 'auto',
                    boxShadow:'inset 0 1.5px 0 0 #ffffff0f'
                },
                paperAnchorRight:{
                    width: 250
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: '#222',
                    fontWeight: 'normal',
                    fontFamily: 'Inter'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#202E66',
                    // background: 'linear-gradient(194deg, rgba(255, 255, 255, 0.41) 21.13%, rgba(194, 194, 194, 0.17) 43.89%, rgba(197, 197, 197, 0.17) 67.7%, rgba(241, 241, 241, 0.35) 81.29%)', 
                    // backdropFilter: 'blur(15px)',
                    gap: 16,
                    borderRadius: 12,
                    alignItems: 'center',
                    width: 320,
                    maxWidth: '80%',
                    // minHeight:'70vh'
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingBottom: 24,
                },
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                icon:{
                    display:'none',
                    width:0
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                    paddingLeft: 0,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff',
                    borderRadius: 10,
                    height: 10,
                },
                bar1Determinate: {
                    borderRadius: 10,
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: (themeParam) => `
              & .MuiBottomNavigation-root &.Mui-selected {
                color: ${themeParam.palette.selectedBottomIconColor} !important;
              },
              & .MuiTab-root.Mui-selected{
                color: ${themeParam.palette.selectedBottomIconColor} !important;
                border-top: 2px solid ${themeParam.palette.selectedBottomIconColor};
                border-bottom: 2px solid ${themeParam.palette.selectedBottomIconColor};
              },
            `,
        },
    }
});

theme = responsiveFontSizes(theme);

export default theme;