import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, loginGame, postUrl } from '@utils/ApiAction';
import { storePlayerId } from '@actions';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import 'swiper/css/bundle';
import { storePaths } from '@utils/Tools';
import { LinearProgress, Typography, Tooltip, ClickAwayListener, Link, Dialog, Box, IconButton, Grid, DialogContentText, DialogTitle, Slide, } from '@mui/material';

import { FiEye, FiChevronRight, FiX } from "react-icons/fi";
import { HiMiniArrowUpCircle } from "react-icons/hi2";


const Home = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { accessToken, accountSetting } = useSelector(state => state.general);
    const { player_id, email_verified_at } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletTooltip, setWalletTooltip] = useState({});
    const [data, setData] = useState({});
    const [geneologyDialog, setGeneologyDialog] = useState(false);
    const { username } = useSelector(state => state.user);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [game, setGame] = useState([]);
    const [gameData, setGameData] = useState([]);

    // useEffect(() => {
    //     isMountedRef.current = true;
    //     setLoading(true);
    //     getUrl('dashboard').then(response => {
    //         if (response.status && isMountedRef.current) {
    //             setData({ ...data, ...response.data });
    //         }
    //     }).catch(error => {
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     }).finally(() => {
    //         setLoading(false);
    //     });

    //     return () => isMountedRef.current = false;
    // }, []);

    return (
        <div className='flex-col app-header-margin' style={{ gap: 24 }}>
        </div>
    )
});

const useStyles = makeStyles((theme) => ({
    iconHoverStyle: {
        color: '#667085',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    iconStyle: {
        color: '#FFFFFF',
    },
}));

export default Home;