import { Fragment, useCallback, useEffect } from "react";
import ScrollToTop from '@utils/ScrollToTop';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

//NOTIFICATON AND LOADING
import { private_routes, public_restricted_routes, public_routes, first_login_routes } from '@configs/Router';
// import BottomNavigationBar from '@layouts/BottomNavigationBar';
import NotificationLoading from "@layouts/NotificationLoading";
import TitleBar from '@layouts/TitleBar';
import NotificationLoadingProvider from "@providers/NotificationLoadingProvider";

import '@css/styles.css';
import '@css/util.css';
import { getUrl } from "@utils/ApiAction";
import { storeProfile } from "@actions";

export default function App() {
    const { accessToken } = useSelector(state => state.general);
    const { username, crypto_address } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let location = useLocation();
    let maintenance = location.pathname === '/maintenance' ? true : false;

    const renderPublicRoute = useCallback(() => {
        return public_routes.map((route, index) =>
            <Route key={index} element={route.component} path={route.path} />
        )
    }, []);

    const renderAuthRestrictedRoute = useCallback(() => {
        return public_restricted_routes.map((route, index) =>
            <Route key={index} element={accessToken ? <Navigate to="/" replace={true} /> : route.component} path={route.path} />
        )
    }, [accessToken]);

    const renderPrivateRoute = useCallback(() => {
        return private_routes.map((route, index) =>
            <Route key={index} element={accessToken ? route.component : <Navigate to="/" replace={true} />} path={route.path} />
        )
    }, [accessToken]);

    const renderFirstTimeRoute = useCallback(() => {
        return first_login_routes.map((route, index) =>
            <Route key={index} element={accessToken ? route.component : <Navigate to="/" replace={true} />} path={route.path} />
        )
    }, [accessToken]);

    useEffect(() => {
        if (accessToken && !maintenance) {
            getUrl('/user').then(response => {
                if (response.status) {
                    dispatch(storeProfile(response.data));
                } else {
                    addAlert('', t('error.contactSupport'), 'error', '');
                }
            }).catch((error) => {
            })
        }
        // eslint-disable-next-line
    }, [accessToken, maintenance]);

    return (
        <Fragment>
            <NotificationLoadingProvider>
                <ScrollToTop />
                <NotificationLoading />
                {/* {!maintenance ? <TitleBar showTitle={true} /> : null} */}
                <TitleBar showTitle={true} />
                {
                    accessToken ?
                        <>
                            {
                                ((!username)) ?
                                    <Routes>
                                        {renderFirstTimeRoute()}
                                        <Route path="*" element={accessToken ? <Navigate replace to="/complete-profile" /> : <Navigate replace to="/complete-profile" />} />
                                        {/* <Route path="*" element={<NotFound />} /> */}
                                    </Routes>
                                    :
                                    <Routes>
                                        {renderPublicRoute()}
                                        {renderAuthRestrictedRoute()}
                                        {renderPrivateRoute()}
                                        <Route path="*" element={accessToken ? <Navigate replace to="/" /> : <Navigate replace to="/" />} />
                                        {/* <Route path="*" element={<NotFound />} /> */}
                                    </Routes>

                            }
                        </>
                        :
                        <Routes>
                            {renderPublicRoute()}
                            {renderAuthRestrictedRoute()}
                            {renderPrivateRoute()}
                            <Route path="*" element={accessToken ? <Navigate replace to="/" /> : <Navigate replace to="/" />} />
                            {/* <Route path="*" element={<NotFound />} /> */}
                        </Routes>
                }
                {/* <BottomNavigationBar /> */}
            </NotificationLoadingProvider>
        </Fragment>
    );
};